import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MetaProperties from '../generic/MetaProperties';
import Table from '../generic/Table';

export const Page = ({ techniquesByCategory }) => (
  <>
    <MetaProperties title="Techniques" />
    <div className="techniques-by-category__wrap gel-wrap">
      {techniquesByCategory.map(
        ({ category, techniques }) =>
          techniques &&
          techniques.length > 0 && <Table key={category} title={category} items={techniques} />
      )}
    </div>
  </>
);

Page.propTypes = {
  techniquesByCategory: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      techniques: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default connect(state => ({
  techniquesByCategory: state.techniquesIndexReducer.techniquesByCategory,
}))(Page);

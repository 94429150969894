import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

if (process.env.CLIENT) {
  require('./Table.scss'); // eslint-disable-line global-require
}

const Table = ({ title, items }) => (
  <div key={`table-${title}`} className="table-container">
    <h1 className="table__title gel-double-pica">{capitalize(title)}</h1>
    <ol className="table__layout gel-layout">
      {items.map(({ id, text, url }) => (
        <li key={id} className="table__layout-item gel-layout__item gel-1/1 gel-1/2@m gel-1/3@xl">
          <a className="table__layout-item__link gel-pica-bold gel-1/1 gel-10/12@m" href={url}>
            {text}
          </a>
        </li>
      ))}
    </ol>
  </div>
);

Table.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Table;
